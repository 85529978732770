.right {
  margin-left: auto;
}

.flexGrow {
  /* flex-grow: 1; */
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
  height: 100%;
}

.emailContainer {
  padding: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.fromField{
  display: flex;
  gap: 4px;
}

.bottomBar{
  display: flex;
}

.fileUpload{
  display: flex;
  align-items: center;
}

.header{
  font-size: 1.2rem;
  font-weight: bold;
  padding: 4px;
}

.releaseTable {
  width: 100%;
  /* overflow: hidden; */
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* Rounded borders */
  margin-top: 20px;
  margin-bottom: 20px;
  /* Margin around the table */
}

.releaseTable th,
.releaseTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.releaseTable th {
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
}

.releaseTable tr:hover {
  background-color: #f0f0f0;
  /* cursor: pointer; */
}

.releaseTable tr.colspan-row {
  max-width: 100%;
  overflow: hidden;
}