#dataGridTheme tbody tr td {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size:small;
    }

    #dataGridTheme thead tr th {
      height: 2.5rem !important;  /* header height for row headers */
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size:small;
      text-align:left !important;
        background-color: #193A6F;
        color: #e2e6f4;
    }

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {  /** Hover color for items in MUI Tables*/
    background-color: #e2e6f4;
    color: #0b2b60;
}

.badge-mine {   /** Badge colors for "Accounts x of y used" and "Users x of y used" */
    color: #ffffff;
    background-color: #193A6F;
}
/*Georgia, 'Times New Roman', Times, serif;*/

.material-icons { inline-size: 10px !important; line-height: inherit !important; }