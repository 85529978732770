.table-settings {
    max-height: 500px;
    overflow-y: auto;

    
}
.tableProperties {
    border-collapse: collapse;
     border-spacing: 0;
     width: 100%;
}

.table-main{
    width: 20px;
}

.table-button{
    text-align: right;
    background-color: #E3712C;
    float: right;
}
