/* Style for Add Ons Modal Table */
.tableWrapper {
    /* max-height: 500px; */
    overflow-y: auto;
    text-align: center;
  }
  
  .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-size: 12px;
    border: none;
    margin: 0;
    padding: 0;
  }
  
  .table thead th {
    border: none;
    padding: 0.5rem;
    background-color: #f8f9fa;
  }
  
  .table tbody td {
    border: none;
    padding: 0.5rem;
  }
  

  @keyframes popAndSpiral {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(255, 140, 0, 0.8); /* Darker orange */
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 20px rgba(255, 69, 0, 0.9); /* Even darker shade */
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(255, 140, 0, 0.8); /* Darker orange */
      opacity: 1;
    }
  }
  
  .lockButton {
    background-color: white; /* No black background */
    color: orange !important; /* Icon color */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid orange;
    border-radius: 50%; /* Makes the button circular */
    padding: 10px; /* Adds some padding around the icon */
    animation: popAndSpiral 2s infinite ease-in-out; /* Applies the pop and spiral animation */
  }
  
  .lockButton:hover {
    background-color: #f7f7f7; /* Slightly off-white on hover */
  }
  
  