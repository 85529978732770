//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";


.accordian-bg-products {
    background-color: $gray-300;
}

.nav-link {
    cursor: pointer;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
    .pagination {
            .page-link {
                border-radius: 30px !important;
                margin: 0 3px;
                border: none;
                text-align: center;
                cursor: pointer;
            }
    }
}

.rdw-editor-main
{
    border: 1px solid  $gray-300;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}

.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table{
    padding-top: 0px!important;
}

.square-switch
{
 .react-switch-bg
 {
 border-radius : 5px!important;
 }   
}

input[type="range"] {
    -webkit-appearance: auto!important;
}

.chat-conversation-height {
        min-height: 500px;
}

.expand-cell{
    cursor: pointer!important;
}

// CUSTOM STYLES
.radio-img { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.radio-img + img {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
}
.radio-img:checked + img {
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
            border: 2px solid $primary;
    padding: 2px;
}
.radio-img:active + img {
    opacity: .9;
}
.form-control:disabled, 
.form-control[readonly] {
    background-color: #efeeee;
}
.is-invalid-border {
    border: 1px solid #ff3d60;
}
.alert-danger.header {
    padding: 5px 10px;
    font-size: 90%;
}

.btn-blue {
    color: #fff;
    background-color: $darkblue;
    border-color: $darkblue;
    &:hover {
        background-color: #203E7A;
        border-color: #203E7A;
    }
}
.timeline.address {
    &:before {
        content: "";
        position: absolute;
        width: 3px;
        top: -25px;
        // left: 0;
        left: 50%;
        bottom: 0;
        background-color: #e4ecf0;
        height: 25px;
    }
}
.view-user-btn i {
    vertical-align: middle;
}
.bs-select select {
    margin-left: 0 !important;
}

.nice-dates-navigation, .nice-dates-day {
    color: #111;
  }
  .nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    max-width: 420px;
    transition: none;
  }

  .nice-dates-day:before {
      background-color: $primary;
  }

  .nice-dates-day:after {
      border: 2px solid $primary;
  }

  .date-range {
    display: flex;
    justify-content: space-between;
}
.date-range .input {
    width: 100%;
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #d3dde6;
    color: #0e3254;
    display: block;
    font-family: inherit;
    font-size: inherit;
    height: 35px;
    outline: none;
    padding: 0 12px;
    transition: border-color .2s;
}
.date-range_arrow {
    flex-shrink: 0;
    position: relative;
    width: 36px;
}
.date-range_arrow:before {
    border-right: 2px solid #d3dde6;
    border-top: 2px solid #d3dde6;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 18px;
    left: 50%;
    margin-left: -14px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 18px;
}

#daterangepicker {
    top: 39px !important; 
    left:-webkit-calc(100% - 680px);
    left:-moz-calc(100% - 680px);
    left:calc(100% - 680px) !important;
    @media only screen and (max-width: 700px) {
        left:-webkit-calc(100% - 300px);
        left:-moz-calc(100% - 300px);
        left:calc(100% - 300px) !important;
    }
    &::before,
    &::after {
        display: none !important;
    }
}

tbody + thead{
    display: none;
}

.iconify{
  font-size: 50px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #E5EAF9;
}

table.mdb-dataTable tbody > tr:not(.selected):hover {
    background-color: rgba(70, 70, 70, 0.7) !important;
    color: #fff !important;
}

.widget-card {
    border-radius: 13px;
    width: auto;
        align-items: center;
        background-color: #e2e6f4;
    .widget-card-body {
        border-radius: 10px;
        border: none;
        background-color: #e2e6f4;
        
    }
}

.mask {
    position: relative;
}

.mask:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(70, 70, 70, 0.7);
    z-index: 1;
}
.msts {
	user-select: none;
	cursor: default;
}

.msts_disabled {
}

.msts__heading {
	display: flex;
}

.msts__subheading {
	display: flex;
}

.msts__body {
	display: flex;
}

.msts__footer {
	display: flex;
}

.msts__side {
	width: 50%;
}

.msts__side_available {
}

.msts__side_selected {
}

.msts__side_controls {
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.msts__list {
	padding: 0;
	margin: 0;
}

.msts__list-item {
	list-style-type: none;
	cursor: pointer;
}

.msts__list-item_disabled {
	cursor: default;
    background-color: #d3d3d3 !important;
}

.msts__list-item_highlighted {
}

.msts__control {
}

.msts__control_select-all {
}

.msts__control_deselect-all {
}

.msts__filter-input {
}
/* Example theme */
.msts_theme_example {
	border: 1px solid silver;
	
	.msts__heading {
		.msts__side {
			padding: 5px;
			text-align: center;
			color: #fff;
			font-weight: bold;
		}

		.msts__side_available {
			background-color: #343a40;
		}

		.msts__side_selected {
			background-color:  #343a40;
		}
	}

	.msts__subheading {
		.msts__side_filter {
			padding: 5px;
		}
	}

	.msts__footer {
		.msts__side {
			padding: 5px 15px;
			background-color: #ecf0f1;
			font-size: 0.75em;
			color: #7f8c8d;
			text-align: right;
		}
	}

	.msts__list {
		height: 140px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.msts__list-item {
		padding: 5px 10px;
		transition: background-color ease-in 0.1s, color ease-in 0.1s;

		&:hover {
			background-color: #2980b9;
			color: #fff;
		}

		&_disabled {
			background-color: #ecf0f1;
			color: #7f8c8d;

			&:hover {
				background-color: #ecf0f1;
				color: #7f8c8d;
			}
		}

		&_highlighted {
			background-color: rgba(41, 128, 185, 0.25);
		}
	}

	.msts__control {
		border: none;
		background: none;
		cursor: pointer;
		padding: 10px 3px;
		color: #bdc3c7;
		transition: color ease 0.15s;

		&:hover {
			color: #95a5a6;
		}

		&[disabled] {
			color: #ecf0f1;
		}
	}

	.msts__control_select-all {
		&:after {
			content: '❯';
		}
	}

	.msts__control_deselect-all {
		&:after {
			content: '❮';
		}
	}

	.msts__filter {
		position: relative;
	}

	.msts__filter-input {
		width: 100%;
		box-sizing: border-box;
		padding: 5px;
		border: 1px solid silver;
	}

	.msts__filter-clear {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 10px;
		font-size: 1.25em;
		color: silver;
		transition: color ease 0.15s;

		&:after {
			content: '×';
			vertical-align: middle;
		}

		&:hover {
			color: #c0392b;
		}
	}
}

.msts_theme_example.msts_disabled {
	background-color: #ecf0f1;
}