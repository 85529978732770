.pageHeader{
  font-size: medium;  
}

.headerContainer{
  display: flex;
  justify-content: space-between;
}

.header{
  font-size: 1.2rem;
  font-weight: bold;
  padding: 4px;
}

.modalHeader{
  font-size: 1.2rem;
  color: white !important;
}

.uploadIcon{
  cursor: pointer;
  color: darkorange;
}