//
// _helper.scss
//

// Font Family
.font-family-secondary {
  font-family: $font-family-secondary;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 1px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// overlay

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: $black;
}
// .iframe-container {display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;min-height: 81vh; padding-top: 7%;}
// .iframe-first-row {background-color: lime; }
// .iframe-second-row { flex-grow: 1; border: none; margin: 0; padding: 0; }

iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
#tableauDiv {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  //navbar height
  margin-top: 70px;
  position: relative;
  //footer height
  margin-bottom: 60px;
}
#tableauDivErr {
  overflow: hidden;
  /* 16:9 aspect ratio */
  //navbar height
  margin-top: 250px;
  position: relative;
  margin-left: 100px;
  //footer height
  margin-bottom: 60px;
}
#elasticsearchid {
  display: none;
}
#tableauDivLoading {
  overflow: hidden;
  /* 16:9 aspect ratio */
  //navbar height
  margin-top: 350px;
  // position: relative;
  // margin-left: 500px;
  padding-left: 40%;
  //footer height
  margin-bottom: 60px;
}

#kibanaDashboardLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed;
  top: 0;
  left: 0;
//   background-color: #ffffff; /* Optional: Set background color if needed */
//   z-index: 9999; /* Ensure it overlays other content */
}

.kibana-loading-container {
  text-align: center;
}

#userList {
  list-style-position: inside;
  // list-style-type: none;
  color: darkred;
  margin: 0;
  padding: 0;
}
#accountStatusNote {
  font-size: 0.65rem;
  color: gray;
}
#accountStatusGuideNote {
  border-bottom: 1px solid gray;
  font-size: 0.65rem;
  color: gray;
}
#accountStatusInactive {
  font-weight: bold;
}
