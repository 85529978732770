.syntaxHighlighterContainer {
  max-height: 400px;
  /* Adjust the max height as needed */
  overflow-y: auto;
  width: 100%;
}

.codeContainer {
  width: 100%;
}

.iaacHeaderContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin: 10px 0px;
}

.tableContainer{
  overflow: scroll;
  height: 60vh;
}

.historyTable {
  width: 100%;
  /* overflow: hidden; */
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* Rounded borders */
  margin-top: 20px;
  margin-bottom: 20px;
  /* Margin around the table */
}

.historyTable th,
.historyTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.historyTable th {
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
}

.historyTable tr:hover {
  background-color: #f0f0f0;
  /* cursor: pointer; */
}

.historyTable tr.colspan-row {
  max-width: 100%;
  overflow: hidden;
}

.clickable{
  cursor: pointer;
}

.expandedComponent {
  padding: 20px;
  /* height: 400px; */
  max-width: 100%;
  overflow-y: auto;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.issueHeader{
  background-color: white;
  border: 1px solid #ccc;
  padding: 4px;
  display: flex;  
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.copyIcon{
  margin-left: auto;
  cursor: pointer;
}

.issueMain {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
}

.severityLogo {
  margin: 8px 4px 0px 4px;
  text-align: center;
  width: 40px;
  background-color: white;
  /* height: 100%; */
}

.issueContainer {
  background-color: white;
  /* border: 1px solid #ccc; */
}

.severityContainer{
  display: flex;
  flex-wrap: wrap;
}

.IACIssue{
  margin-bottom: 10px;
}

.severityLevel{
  background-color: #DB5969; 
  padding: 4px;
  color: white; 
  display: inline-block; 
}

.severityDescription{
  background-color: #DD6B79; 
  padding: 4px;
  color: white; 
  display: inline-block;
  flex: 1;
}
.incorrectInput{
  /* color: white !important; */
  color: #DD6B79;
  padding: 2px;
}

.expectedInput{
  /* color: black !important; */
  color: green;
  padding: 2px;
}

.header{
  font-size: 1.2rem;
  font-weight: bold;
  padding: 4px;
}

.scanHeader{
  display: flex;
  justify-content: space-between;
}

.text {
  padding: 4px;
}

.tableStyle{
  width: 100%;
  border-collapse: collapse;
}

.cellStyle {
  text-align: left;
  border-bottom: none !important;
  padding: 0px 4px !important;
}

.cellHeaderStyle{
  text-align: left;
  width: 50px;
  font-weight: bold;
  padding: 0px 4px !important;
  border-bottom: none !important;
}

.fileHeader{
  display: flex;
  color: red;
  justify-content: space-between;
}

.fileName{
  text-align: left;
  font-weight: bold;
  padding: 0px 4px !important;
  border-bottom: none !important;
}

.fileIssueContainer {
  border: 1px solid #ccc;
  margin-bottom: 4px;
  margin-right: 4px;
}

.result {
  font-weight: bold;
}

.lineNumber{
  width: 8rem;
  text-align: right;
  color: red;
  margin-right: 4px;
}

.iconButton{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
}

.fileUploadContainer{
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
}

.fileUploadCard {
  /* padding: 4px; */
  height: 500px;
  overflow-y: auto;
  width: 50%;
  /* background-color: lightblue; */
  /* border-radius: 4px; */
  background: white;
  /* border-radius: 8px; */
  /* border: 1px solid #CCC; */
  /* height: 100%; */
  align-items: center;
  /* box-shadow: 4px 4px 10px gray; */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.fileUploadCardHeader {
  display: flex;
  /* color: white;
    background-color: rgb(25, 58, 111); */
  color: black;
  background-color: #F5F5F5;
  /* width: 100%; */
  justify-content: space-between;
  padding-left: 1rem;
  /* height: 20px; */
  font-weight: bold;
  /* height: 100%; */
  line-height: 30px;
  /* border-top-right-radius: 8px;
  border-top-left-radius: 8px; */
  border-bottom: 1px solid #CCC;
}

.commentFieldsHeader{
  display: flex;
    color: black;
    justify-content: space-between;
}

.fileUploadSteps{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.fileUploadStep {
  /* margin: 4px; */
  /* padding: 4px; */
  display: flex;
  align-items: center;
  /* gap: 10px; */
  border: 1px solid #193A6F;
  border-radius: 4px;
  /* height: 40px; */
  justify-content: center;
  /* align-items: center; */
}

.spinner{
  margin-left: 4px;
}

.fileUploadStepNumber{
  background-color: #193A6F;
  color: white;
  padding: 8px;
  width: 150px;
}

.fileUploadStepButton{
  align-self: center;
  align-items: center;
  display: flex;
  gap: 4px;
  /* width: 100%; */
}


.file-input-container {
  display: flex;
  align-items: center;
}

.file-input-label {
  color: blue;
  /* Change the button color to blue */
  margin-right: 10px;
}

.file-input {
  display: none;
  /* Hide the actual input */
}

.file-name {
  flex-grow: 1;
  /* Expand to fill available space and display the file name on the left */
}

.customFileUpload{
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: #F98125;
  display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: red;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}

.inputfile:focus+label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
  pointer-events: none;
}

.addIcon, .buttonIcon{
  cursor: pointer;
}

.metricDisplayContainer {
  /* padding: 4px;
  /* width: 150px; */
  /* height: 150px; */
  /* background-color: #eee; */
  border-radius: 4px;
  border: 1px solid #193A6F; 
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}

.metric {
  align-items: center;
  display: flex;
  width: 250px;
  flex-direction: row;
  padding: 20px 10px;
  /* border: 1px solid #193A6F; */
  /* border-radius: 4px; */
  justify-content: space-evenly;
}
.vulnerabilities{
  width: fit-content;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  /* border: 1px solid #193A6F;
  border-radius: 4px; */
  justify-content: space-around;
}
.metricText{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.bigNumber {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
  /* You can change the color to your preference */
}

.metricName {
  font-size: 14px;
  color: #333;
  /* You can change the color to your preference */
}

.vulnerabilities {
  display: flex;
  flex-direction: row;
}
.vulnerabilityContainer {
  position: relative;
  margin: 10px;
  border-radius: 50%;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.vulnerabilityContainer::before {
  content: '';
  position: absolute;
  top: -5px; 
  left: -5px; 
  width: 70px; 
  height: 70px; 
  border-radius: 50%;
  border: 1px solid #000; 
  box-sizing: border-box;
}

/* .highMetric, .mediumMetric, .lowMetric {
  width: 150px;
  text-align: center;
} */

.criticalMetric{
  background-color: darkred;
}

.highMetric{
  background-color: red;
}

.mediumMetric{
  background-color: orange;
}

.lowMetric{
  background-color: #FFD7B5;
}

.infoMetric{
  background-color: #007BFF;
}

.severityCounters{
  display: flex;
  padding: 1rem;
  gap: 1rem;
  justify-content: space-around;
}

.severityCounter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.severityLabel{
  font-weight: bold;
  text-transform: uppercase;
}

.selectedSeverityLabel{
  color: red;
}

.vulMetricType {
  font-size: 10px;
}

.vulMetricValue {
  font-size: 18px;
}


.show {
  display: block;
}

.hide{
  display: none;
}

.success{
  background-color: #90EE90;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
}

.error {
  background-color: #ffcccb;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
}

.chartContainer{
display: inline-block;
}

.apiSuccessMessage{
  align-self: center;
  color: green;
}

.apiFailureMessage{
  align-self: center;
  color: red;
}

.fileLabel {
  align-self: center;
}

.iaacScannerCard{
  width: 50%;
  height: 500px;
}

.websocketScannerContainer{
  /* max-width: 600px; */
  /* margin: auto; */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 500px;
  /* overflow: auto; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.messageListContainer{
  overflow-y: auto;
  max-height: 400px;
}

.messageList{
  list-style-type: none;
  padding: 0;
}

.messageItem{
  margin: 5px 0;
  padding: 10px;
  /* background-color: #f0f0f0; */
  /* border-radius: 5px; */
  word-wrap: break-word;
}

.connectionStatus{
  margin-bottom: 10px;
  font-weight: bold;
}

.connected {
  color: green;
}

.disconnected {
  color: red;
}

.messageIndicator{
  display: inline-block;
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 14px;
  color: #fff !important;
  border-radius: 50%;
  background-color: green; /* Optional: add a white background to fill the circle */
  padding: 3px; 
}

.messageTimestamp{
  color: #888;
  font-size: 12px;
  margin-right: 5px;
}

.errorStep &.MuiStepIcon-root{
  color: red !important;
}

.errorInfo {
  color: red;
}

.note {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #dadde9;
  border-radius: 5px;
  background-color: #f5f5f9;
  text-align: left;
}

.note p {
  margin: 0;
  font-weight: bold;
}

.note ul {
  padding-left: 20px;
}


.supportedPlatformsContainer {
  padding: 20px;
}

.header {
  text-align: left;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.logosGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  justify-items: center;
}

.logo {
  height: 50px; /* Adjust the height as needed */
  max-width: 100px; /* Adjust the width as needed */
  object-fit: contain;
}