.verification-form {
  display: flex;
  gap: 10px;
  /* Adjust the gap as needed */
}

.verification-digit-input {
  flex: 1;
  width: 2rem;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* To create background */
.background-pattern1 {
  position: absolute;
  top: -100px;
  left: 0px;
  width: 100%;
  opacity: 0.7;
  height: auto;
  pointer-events: none;
  z-index: -1;
}
.background-pattern2 {
  position: absolute;
  rotate: 180deg;
  bottom: -100px;
  left: 0px;
  width: 100%;
  opacity: 0.7;
  height: auto;
  pointer-events: none;
  z-index: -1;
}

.phone-input input{
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.phone-input.invalid input{
  border: 1px solid #d32f2f;
}

.phone-error{
  color: #d32f2f;
}
